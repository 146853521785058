/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('close-account', {
      redirectTo: '.landing',
      parent: 'root',
      url: '/close-account',
      templateUrl: 'apps/close-account/templates/acp-close-account.ng.html',
      data: {
        permissions: {
          only: 'isOac4CloseAccountEnabled',
          redirectTo: {
            isOac4CloseAccountEnabled: 'dashboard'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.close-account" */ 'apps/close-account'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('close-account.security-check', {
      template:
        '<acp-close-account-security-check></acp-close-account-security-check>'
    })
    .state('close-account.landing', {
      template: '<acp-close-account-landing></acp-close-account-landing>',
      data: {
        permissions: {
          only: 'isSecurityVerified',
          redirectTo: {
            isSecurityVerified: 'close-account.security-check'
          }
        }
      }
    });
}

export default states;
